<template>
  <div v-if="getCurrentDetail" class="shop-pos-detail-wrap">
    <div class="back-to-list-text cus-is-button" @click="checkSave()">
      <el-icon style="margin-right: 18px"><Back /></el-icon>
      返回列表
    </div>
    <el-form
      :model="ruleForm"
      status-icon
      hide-required-asterisk
      class="pos-form"
      label-position="left"
      label-width="140px"
      size="large"
    >
      <div class="d-flex" style="align-items: center">
        <p class="shop-label">門市</p>
        <p class="shop-name">{{ getCurrentDetail.shop_name }}</p>
      </div>
      <el-form-item v-for="(item, index) in posSetting" :key="index">
        <template #label>
          <span class="label">{{ item.label }}</span>
        </template>
        <el-checkbox
          v-for="(option, o_index) in item.array"
          v-model="ruleForm[option.value]"
          :key="o_index"
          :label="option.label"
          size="large"
          @change="item.label == '來源' ? changeCheckbox(option.value) : ''"
          :disabled="item.label == '不裁切發票及明細' ? (ruleForm[option.disabled] ? false : true) : false"
        />
      </el-form-item>
      <el-tabs v-model="activeName" style="height: 300px; margin-top: 30px">
        <el-tab-pane v-for="(pos, index) in posList" :key="index" :label="pos.label" :name="pos.value">
          <el-form-item style="margin-bottom: 10px">
            <template #label>
              <span class="label">貼紙尺寸</span>
            </template>
            <el-radio-group v-model="ruleForm.client[index].label_printer_label_type" size="large">
              <el-radio :label="0">小</el-radio>
              <el-radio :label="1">大</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item style="margin-bottom: 10px">
            <template #label>
              <span class="label">列印後是否裁切</span>
            </template>
            <el-switch
              v-model="ruleForm.client[index].label_printer_cut"
              size="large"
              inline-prompt
              active-text="是"
              inactive-text="否"
              style="--el-switch-on-color: #059300; --el-switch-off-color: #db5959"
            />
          </el-form-item>
          <el-form-item style="margin-bottom: 10px">
            <template #label>
              <span class="label">印表機型號</span>
            </template>
            <el-select v-model="ruleForm.client[index].receipt_printer_name" placeholder="請選擇" size="default">
              <el-option label="TMT70" value="TMT70" />
              <el-option label="TMT70II" value="TMT70II" />
              <el-option label="TMT70RJ45" value="TMT70RJ45" />
            </el-select>
          </el-form-item>
        </el-tab-pane>
      </el-tabs>
    </el-form>
    <div class="button-wrap">
      <el-button type="primary" style="width: 100px" @click="submit">儲存</el-button>
    </div>
  </div>
</template>
<script>
import { useRoute, useRouter, onBeforeRouteLeave } from 'vue-router';
import { ref, computed, watch, reactive, getCurrentInstance } from 'vue';
import { useGetters, useMutations, useActions } from '@/utils/mapStore';
import {
  source_options_without_inside,
  not_cut_invoices_options,
  take_away_options,
  card_pay_options,
  third_party_pay_options,
  other_pay_options,
} from '@/utils/define';
export default {
  name: 'shop-pos-detail',
  setup() {
    const { proxy } = getCurrentInstance();
    const router = useRouter();
    const route = useRoute();
    //偵測沒有資料
    const getCurrentDetail = useGetters('shopSystem', ['getCurrentDetail']);
    const CLEAR_CURRENT_DETAIL = useMutations('shopSystem', ['CLEAR_CURRENT_DETAIL']);
    if (!getCurrentDetail.value) router.push('/system/shop?page=1');
    watch(route, () => {
      if (!route.fullPath.includes('/detail')) CLEAR_CURRENT_DETAIL();
    });
    //設定清單
    const posSetting = reactive([
      { label: '來源', array: source_options_without_inside, value: 'source' },
      { label: '不裁切發票及明細', array: not_cut_invoices_options, value: 'not_cut_invoice' },
      { label: '類型', array: take_away_options, value: 'take_away_method' },
      { label: '卡片支付', array: card_pay_options, value: 'card_pay' },
      { label: '第三方支付', array: third_party_pay_options, value: 'third_party_pay' },
      { label: '其他支付', array: other_pay_options, value: 'other_pay' },
    ]);
    const ruleForm = ref(JSON.parse(JSON.stringify(getCurrentDetail.value?.config || {})));
    //取得POS清單
    const posList = computed(() => {
      let arr = [];
      if (getCurrentDetail.value?.config) {
        getCurrentDetail.value?.config.client.forEach((e) => {
          arr.push({ label: `機台${e.client_id}`, value: e.client_id });
        });
      }
      return arr;
    });
    const activeName = ref(getCurrentDetail.value?.config.client[0]?.client_id);

    //檢查未儲存
    const getConfigIsChange = useGetters('shopSystem', ['getConfigIsChange']);
    const SET_IS_CHANGE = useMutations('shopSystem', ['SET_IS_CHANGE']);
    watch(
      ruleForm.value,
      () => {
        if (JSON.stringify(ruleForm.value) !== JSON.stringify(getCurrentDetail.value?.config)) SET_IS_CHANGE(true);
        else SET_IS_CHANGE(false);
      },
      { deep: true }
    );
    const checkSave = (next) => {
      if (getConfigIsChange.value) {
        proxy
          .$confirm(`變更尚未儲存，確定要離開此頁？`, '尚未儲存', {
            confirmButtonText: '離開',
            cancelButtonText: '取消',
            type: 'warning',
            confirmButtonClass: 'el-button--danger',
          })
          .then(() => {
            if (next) next();
            else router.go(-1);
          })
          .catch((e) => e);
        return false;
      } else {
        if (next) next();
        else router.go(-1);
      }
    };
    onBeforeRouteLeave((to, from, next) => checkSave(next));

    //送出
    const doSaveShopConfigDetail = useActions('shopSystem', ['doSaveShopConfigDetail']);
    const submit = async () => {
      console.log(ruleForm.value);
      await doSaveShopConfigDetail(ruleForm.value);
      proxy.$message({
        type: 'success',
        message: `已儲存變更`,
      });
    };

    // 若來源未選，不裁切發票及明細disabled
    const changeCheckbox = function (value) {
      if (ruleForm.value[value] == false) ruleForm.value[`no_cut_invoice.${value}`] = false;
    };

    return { posSetting, ruleForm, posList, activeName, getCurrentDetail, changeCheckbox, checkSave, submit };
  },
};
</script>
<style lang="scss" scoped>
.shop-pos-detail-wrap {
  width: 100%;
  height: fit-content;
  position: relative;
  overflow: scroll;
  @include white-bg;
  padding: 22px;
}
.button-wrap {
  position: absolute;
  bottom: 20px;
  right: 20px;
}
.pos-form {
  margin-top: 50px;
  .shop-label {
    width: 140px;
    height: 40px;
    line-height: 40px;
    color: $color-text;
    font-weight: 500;
  }
  .shop-name {
    font-size: 1.4rem;
    color: $color-text-light;
  }
  .el-form-item {
    margin-bottom: 0;
  }
  .label {
    font-size: 1.5rem;
    color: $color-text;
    font-weight: 500;
  }
}
</style>
